import { useCallback, useState } from 'react';

import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';
import { passwordReset, passwordResetRequest } from 'fetcher';
import { useResetPasswordValidateToken } from 'hooks';
import { openNotification, regexPass } from 'utils';
import { CircularProgress } from 'components';
import { NotFound } from 'routes/404';
import { fetchError, fetchStart } from 'slices/commonSlice';

import { Login } from './Login';

export const ResetPassword = ({ noToken }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [form] = Form.useForm();

  const token = location.pathname.replaceAll('/parola-sifirlama/', '');

  const { isValidate, user } = useResetPasswordValidateToken({
    token,
    hasToken: !noToken,
  });
  const [toggleMessage, setToggleMessage] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [disableAll, setDisableAll] = useState(false);

  const onFinish = useCallback(
    async (values) => {
      setButtonLoading(true);
      dispatch(fetchStart());

      if (!noToken) {
        const data = { token, password: values.password };

        try {
          const res = await passwordReset(data);

          if (res) {
            setDisableAll(true);
            setToggleMessage(true);
            setButtonLoading(false);

            openNotification({
              message: formatMessage({
                id: 'app.userAuth.PassResetComplettedInfo',
              }),
            });
            setTimeout(() => {
              navigate('/');
            }, 3000);
          }
        } catch (error) {
          dispatch(
            fetchError(
              formatMessage({
                id: 'app.error',
              })
            )
          );
        }
      } else {
        try {
          const res = await passwordResetRequest(values);

          if (res !== 429) {
            setToggleMessage(true);
            setButtonLoading(false);

            openNotification({
              message: formatMessage({
                id: 'app.userAuth.PassResetEmailSuccess',
              }),
            });
          } else {
            dispatch(
              fetchError(
                formatMessage({
                  id: 'app.userAuth.PassResetEmailSoMuchRequest',
                })
              )
            );

            setButtonLoading(false);
            setDisableAll(true);
          }
        } catch (error) {
          dispatch(
            fetchError(
              formatMessage({
                id: 'app.userAuth.PassResetEmailSoMuchRequest',
              })
            )
          );
          setButtonLoading(false);
          setDisableAll(false);
        }
      }
    },
    [dispatch, noToken, navigate, token, formatMessage]
  );

  if (buttonLoading) return <CircularProgress />;

  if (!noToken && !isValidate) {
    return (
      <NotFound
        text={formatMessage({
          id: 'app.userAuth.PassResetTokenTimeout',
        })}
      />
    );
  }

  return (
    <Login>
      <h4 className="gx-mx-auto gx-w-75 gx-text-center gx-font-weight-semi-bold  gx-text-grey">
        <FormattedMessage id="app.userAuth.HeaderResetPass" />
      </h4>

      {noToken && (
        <div className="gx-text-light gx-fs-sm gx-mt-2 gx-text-center">
          <FormattedMessage id="app.userAuth.HeaderResetPassInfo" />
        </div>
      )}

      <div className="gx-m-4 gx-p-2">
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={onFinish}
          className="gx-signin-form gx-form-row0"
          size="large"
          form={form}
        >
          {!noToken ? (
            <>
              <Form.Item className="gx-mb-3">
                <Input
                  value={user?.email}
                  disabled
                />
              </Form.Item>
              <Form.Item
                className="gx-mb-3"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'app.userAuth.PassError',
                    }),
                  },
                  {
                    pattern: regexPass,
                    message: formatMessage({
                      id: 'app.userAuth.PassResetInfoMessageType',
                    }),
                  },
                  {
                    min: 8,
                    message: formatMessage({
                      id: 'app.userAuth.PassResetInfoMessageCharacter',
                    }),
                  },
                ]}
                name="password"
                hasFeedback
              >
                <Input.Password
                  type="password"
                  placeholder={formatMessage({
                    id: 'userEdit.userPassNew',
                  })}
                  disabled={disableAll || !isValidate}
                />
              </Form.Item>
              <Form.Item
                className="gx-mb-3"
                name="passwordCheck"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'app.userAuth.PassAgainError',
                    }),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          formatMessage({
                            id: 'app.userAuth.PassNotMatch',
                          })
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder={formatMessage({
                    id: 'userEdit.userPassAgain',
                  })}
                  disabled={disableAll || !isValidate}
                />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              className="gx-mb-3"
              rules={[
                {
                  required: true,
                  message: formatMessage({
                    id: 'app.userAuth.EmailErrorCheck',
                  }),
                  type: 'email',
                },
              ]}
              name="email"
            >
              <Input placeholder={formatMessage({ id: 'appModule.email' })} />
            </Form.Item>
          )}

          <Form.Item className="gx-text-right">
            <Button
              type="primary"
              className="gx-mb-0  gx-w-100"
              htmlType="submit"
              loading={buttonLoading}
              disabled={toggleMessage || disableAll || (!isValidate && !noToken)}
            >
              <FormattedMessage id="form.send" />
            </Button>
          </Form.Item>

          {noToken && (
            <div className="gx-text-light gx-fs-sm gx-mt-2 gx-text-center">
              <FormattedMessage id="app.userAuth.BottomResetPassInfo" />
            </div>
          )}

          {toggleMessage && noToken && (
            <div
              style={{ fontSize: 14 }}
              className="gx-fs-sm gx-mx-auto gx-my-0 gx-text-center gx-mt-2 gx-d-flex gx-justify-content-center gx-link"
              onClick={() => setToggleMessage(!toggleMessage)}
            >
              <FormattedMessage id="app.userAuth.PassResetEmailAgain" />
            </div>
          )}
        </Form>
      </div>
    </Login>
  );
};
