import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useGetCurrentUserQuery } from 'services';

import { ArticleOrderAccepted } from './status';
import { ArticleOrderExpired } from './status/ArticleOrderExpired';
import ArticleOrderStatusItem from './ArticleOrderStatusItem';

export const ArticleOrderStatus = ({ processData, offer }) => {
  const { data: current_user } = useGetCurrentUserQuery();
  const processItem = processData.filter((item) => item.author && item.type === 0 && item.author.user && item.opinion);

  const notAnsweredAuthor = processData.find((item) => item.type === 0 && item.opinion === 'notAnswered');

  const isProcessJoint = processData.some((item) => item.type !== 0);
  const lastProcess = processData && processData[processData.length - 1];
  const content = {
    pending: {
      icon: <ClockCircleOutlined />,
      key: 'article.order',
      component: <ArticleOrderAccepted offer={offer} />,
    },
    approved: {
      icon: <CheckCircleOutlined />,
      key: 'article.order',
      component: isProcessJoint && <ArticleOrderAccepted offer={offer} />,
    },
    rejected: {
      icon: <CloseCircleOutlined />,
      key: 'article.order',
    },
    notAnswered: {
      icon: <ClockCircleOutlined />,
      hide: current_user?.isOrderUnit || current_user?.isAdmin,
      key: 'article.order',
      component: (
        <ArticleOrderExpired
          offer={offer}
          notAnsweredAuthor={notAnsweredAuthor}
        />
      ),
    },
  };

  return (
    <>
      {isProcessJoint ? (
        <ArticleOrderStatusItem
          content={content}
          offerStatus={offer.status}
          type={lastProcess.type}
          track={lastProcess.opinion}
          opinionDate={lastProcess.opinionDate}
          finishDate={lastProcess.finishDate}
        />
      ) : (
        processItem.map((item) => (
          <ArticleOrderStatusItem
            key={item.author.user.id}
            type={item.type}
            offerStatus={offer.status}
            content={content}
            track={item.opinion}
            opinionDate={item.opinionDate}
            finishDate={item.finishDate}
            authorName={item?.author?.user?.fullName}
            authorID={item?.author?.user?.id}
          />
        ))
      )}
    </>
  );
};
