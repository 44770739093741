import { Col, Row } from 'antd';

import UploadAgreement from './UploadAgreement';
import EditArticleOrderDate from './EditArticleOrderDate';

const StartProcessStepActions = ({ id, title, authors, orderDate, deliveryDate, disabled }) => {
  return (
    <Row gutter={24}>
      <Col
        xs={24}
        md={12}
      >
        <EditArticleOrderDate
          id={id}
          orderDate={orderDate}
          deliveryDate={deliveryDate}
          title={title}
          authors={authors.map((item) => item.author)}
        />
      </Col>
      <Col
        xs={24}
        md={12}
      >
        <UploadAgreement
          disabled={disabled}
          offerId={id}
          authorId={authors[0].id}
        />
      </Col>
    </Row>
  );
};

export default StartProcessStepActions;
